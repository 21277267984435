import React from "react";
import { Col, Row, Typography } from "antd";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Pswrd from "./../../../assets/images/pswrd.png"
import Arrow from "./../../../assets/images/arrows.png"
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import axiosClient from "../../../utils/axios.tsx";
const Main = () => {
  const { Title, Text } = Typography;
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const token = searchParams.get("token");


  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Confirm Password is required"),
  });

  return (
    <Row>
      <Col span={24}>
        <div className="w-full flex justify-center pt-28 checkEmail">
          <div className=" w-[500px]">
            <div className="justify-center grid">
              <img
                src="https://ik.imagekit.io/8extk8sjo/Featured%20icon%20(1).svg?updatedAt=1709121905924"
                alt=""
                className="desktop"
              />
              <img src={Pswrd} alt="" className="mobile" />
            </div>

            <Title
              style={{
                fontSize: "30px",
              }}
              className="pt-4 text-[#101828] text-center font-semibold"
            >
              Set new password
            </Title>
            <div className="grid text-center">
              <Text className="text-[16px] -mt-2">
                Your new password must be different to previously used <br />
                passwords.
              </Text>
            </div>

            <div
              style={{
                background: "rgba(255, 255, 255, 1)",
                boxShadow:
                  "0px 2px 4px -2px rgba(16, 24, 40, 0.06),0px 4px 8px -2px rgba(16, 24, 40, 0.1)",
              }}
              className=" p-10 rounded-[12px] mt-8 boxedNEw"
            >
              <Formik
                initialValues={{ password: "", confirmPassword: "" }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  const data = {
                    email: email,
                    token: token,
                    password: values.password,
                  };

                  axiosClient()
                    .post("/users/reset-password", data)
                    .then((res) => {
                      setSubmitting(false);
                      navigate("/successfully-reset");
                    })
                    .catch((error) => {
                      setSubmitting(false);
                      toast.error(error?.response?.data?.message);
                    });
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    {/* Password field */}
                    <div className="mb-4">
                      <Field
                        type="password"
                        name="password"
                        placeholder="Password"
                        className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-500 text-[14px] mt-1"
                      />
                    </div>

                    {/* Confirm Password field */}
                    <div className="mb-4">
                      <Field
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                      />
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="text-red-500 text-[14px] mt-1"
                      />
                    </div>

                    {/* Submit button */}
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="w-full h-[44px] bg-[#155EEF] rounded-[8px] font-semibold text-[16px] text-[#fff]"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Reset password"}
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="text-center mt-8 flex justify-center gap-2 items-center logBox">
            <img
                src={Arrow}
                alt=""
                className="mobile"
              />
              <img
                src="https://ik.imagekit.io/8extk8sjo/Icon%20(16).svg?updatedAt=1709123059299"
                alt=""
                className="desktop"
              />
              <Text
                onClick={() => navigate("/signin")}
                style={{
                  color: "#475467",
                }}
                className=" text-[14px] cursor-pointer font-semibold"
              >
                Back to log in
              </Text>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Main;
