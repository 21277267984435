import React, { useEffect, useState } from "react";
import RightArrow from "../../assets/images/right_arrow.svg";
import { Button, Col, Input, Modal, Spin } from "antd";
import PartnerTable from "./partnerTable.tsx";
import Cn from "../../assets/images/cn.png";
import Plus from "../../assets/images/plun.png";
import Dark from "../../assets/images/copi.png";
import Light from "../../assets/images/url.png";
import Crossed from "../../assets/images/crosed.png";
import LabelInput from "../../components/labelInput.tsx";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useAuthContext } from "../../context/auth-context.tsx";
import { toast } from "react-toastify";
import axiosClient from "../../utils/axios.tsx";
import { Box, Tab, Tabs } from "@mui/material";
import FinanceTable from "./financeTable.tsx";

export default function MainPartner() {
  const [visible, setVisible] = useState(false);
  const [emails, setEmails] = useState([{ email: "" }]);
  const [copied, setCopied] = useState(false);
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [miniPartners, setMiniPartners] = useState([]);
  const [allInvites, setAllInvites] = useState([]);
  const [partnerPayouts, setPartnerPayouts] = useState([]);

  const showModal = () => {
    setVisible(true);
  };

  const handleCopy = () => {
    const textToCopy = `${
      process.env.REACT_APP_MINI_PARTNER_INVITE_DOMAIN || "www.domain.com"
    }?ref=${user?.user_id ? user?.user_id : "no ref"}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  const handleOk = () => {
    // Handle the email submission here
    console.log("Emails:", emails);
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
    setEmails([{ email: "" }]);
  };

  const handleEmailChange = (index, event) => {
    const values = [...emails];
    values[index].email = event.target.value;
    setEmails(values);
  };

  const handleAdd = () => {
    setEmails([...emails, { email: "" }]);
  };

  const handleRemove = (index) => {
    const values = [...emails];
    values.splice(index, 1);
    setEmails(values);
  };

  const handleInvite = () => {
    setIsLoading(true);
    const validEmails = emails.filter((email) => email.email.trim() !== "");
    const uniqueEmails = Array.from(
      new Set(validEmails.map((e) => e.email))
    ).map((email) => ({ email }));

    if (uniqueEmails.length === 0) {
      toast.error("Please enter at least one valid email address.");
      return;
    }

    const data = {
      userId: user?.user_id,
      emails: uniqueEmails?.map((e) => e.email),
    };

    axiosClient()
      .post("users/invite-mini-partner", data)
      .then((res) => {
        if (res?.data?.success) {
          res.data.successfulInvitationEmails.forEach(({ email }) => {
            toast.success(`${email} invited successfully!`, {
              autoClose: 3000,
            });
          });
          res.data.alreadyExistingEmails.forEach(({ email, role }) => {
            toast.warning(`${email} already present as ${role}!`, {
              autoClose: 3000,
            });
          });
          res.data.alreadyInvitedEmails.forEach(({ email, role }) => {
            toast.warning(`${email} already invited as ${role}!`, {
              autoClose: 3000,
            });
          });
          res.data.failedInvitationEmails.forEach(({ email }) => {
            toast.error(`Failed to send invitation to ${email}`, {
              autoClose: 3000,
            });
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
        getInvites();
        handleCancel();
      });
  };

  const CustomBtnIcon = () => {
    return (
      <img
        src="https://ik.imagekit.io/8extk8sjo/Icon%20(27).svg?updatedAt=1709811522884"
        alt=""
        className="w-[15px] h-[15px]"
      />
    );
  };

  const getMiniPartners = async () => {
    axiosClient()
      .get(`/users/super-partner/${user?.user_id}/mini-partners`)
      .then((res) => {
        // console.log("Mini Partners: ",res?.data);
        setMiniPartners(res?.data);
      })
      .catch((err) => console.error(err.message));
  };

  const getInvites = async () => {
    axiosClient()
      .get(`/invites/super-partner/${user?.user_id}/all-invites`)
      .then((res) => {
        // console.log("All Invites: ",res?.data);
        setAllInvites(res?.data);
      })
      .catch((err) => console.error(err.message));
  };

  useEffect(() => {
    getMiniPartners();
    getInvites();
  }, []);

  const getPartnerPayouts = async () => {
    axiosClient()
      .get(
        `users-commissions/super-partner/mini-partner-commissions?userId=${user?.user_id}`
      )
      .then((res) => {
        setPartnerPayouts(res?.data);
        // console.log("Partners Payouts: ", res.data);
      })
      .catch((err) => console.error(err.message));
  };

  useEffect(() => {
    getPartnerPayouts();
  }, []);

  const refreshTable = () => {
    getMiniPartners();
    getInvites();
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <div className="container pt-28 ">
        <h3 className="text-[#101828] text-[30px] font-semibold ">
          Partners Management
        </h3>

        <div className="grid">
          <h3 className="partnerHeading">Partner Invite Link</h3>
        </div>
        <div className="flex items-center mt-4 copyBtn">
          <Input
            className="w-[380px] h-[44px] rounded-l-[8px] placeholder:font-bold placeholder:text-[#667085] font-semibold rounded-r-none border border-[#D0D5DD]"
            placeholder=""
            value={`${
              process.env.REACT_APP_MINI_PARTNER_INVITE_DOMAIN ||
              "www.domain.com"
            }?ref=${user?.user_id ? user?.user_id : "no ref"}`}
          />
          <Button
            type="primary"
            // icon={<CustomBtnIcon />}
            style={{
              border: "1px solid #D0D5DD",
              borderRight: "1px solid #D0D5DD",
              borderBottom: "1px solid #D0D5DD",
              borderRadius: "0px, 8px, 8px, 0px",
              display: "flex",
              alignItems: "center",
            }}
            onClick={handleCopy}
            className="flex-none bg-transparent shadow-none h-[44px] text-[#344054] font-bold rounded-r-[8px] rounded-l-none bg-white"
          >
            <img src={Dark} alt="" className="lightcopy" />
            <img src={Light} alt="" className="darkcopy" />
            {copied ? "Copied!" : "Copy"}
          </Button>
        </div>
      </div>
      <div className="container">
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="custom tabs"
            className="tabsSelect partners"
          >
            <Tab label="Partners" />
            <Tab label="Partners Finance" />
          </Tabs>
        </Box>
        {value === 0 && (
          <>
            <div className="partnerHead">
              <h4>Partners</h4>
              <Button onClick={showModal}>
                <img src={Cn} alt="" /> Invite Partners
              </Button>
            </div>
            <PartnerTable
              data={[...miniPartners, ...allInvites] || []}
              refresh={refreshTable}
            />
          </>
        )}
        {value === 1 && (
          <>
            <div className="partnerHead">
              <h4>Partners</h4>
            </div>
            <FinanceTable data={partnerPayouts || []} />
          </>
        )}
      </div>

      <Modal
        className="invitationModal"
        visible={visible}
        onOk={handleInvite}
        onCancel={handleCancel}
        footer={null}
      >
        <Col className="inviteHead">
          <h3>Invite Partners</h3>
          <img
            src={Crossed}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => setVisible(false)}
          />
        </Col>
        <Col className="inviteBody">
          <label>Email</label>
          {emails.map((email, index) => (
            <Col key={index} className="" style={{ marginBottom: "10px" }}>
              <Col style={{ display: "flex" }}>
                <LabelInput
                  // placeholder="Email"
                  value={email.email}
                  onChange={(event) => handleEmailChange(index, event)}
                />
                {index > 0 && (
                  <DeleteOutlined onClick={() => handleRemove(index)} />
                )}
              </Col>
            </Col>
          ))}
        </Col>
        <Col>
          <span
            // type="dashed"
            onClick={handleAdd}
            style={{
              display: "flex",
              margin: "12px 24px 0",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <img src={Plus} alt="" style={{ marginRight: "5px" }} /> Add more
          </span>
        </Col>
        <Col
          className="modalBtn"
          style={{
            borderTop: "1px solid #EAECF0",
            marginTop: "17px",
            paddingTop: "26px",
            paddingBottom: "24px",
          }}
        >
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleInvite}>
            {isLoading ? <Spin /> : "Send Invite"}
          </Button>
        </Col>
      </Modal>
    </>
  );
}
