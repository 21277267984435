import React from "react";
import ReactApexChart from "react-apexcharts";

const CompanyGraph: React.FC<any> = ({ monthlyData }) => {
  // console.log("Graph Data: ", monthlyData);
  // Define the series and options state
  // const [series] = React.useState(
  //   monthlyData
  //     ? [monthlyData]
  //     :
  //      [
  //         {
  //           name: "Own Companies",
  //           data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // Data from Jan to Dec
  //         },
  //       ]
  // );

  const series = React.useMemo(() => {
    return monthlyData
      ? [monthlyData]
      : [
          {
            name: "Own Companies",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
        ];
  }, [monthlyData]);

  const [options] = React.useState({
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [2, 2], // Make the curve lines thinner
      curve: "smooth",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec", // From Jan to Dec
      ],
    },
    yaxis: {
      title: {
        text: undefined,
      },
      tickAmount: 4,
    },
    tooltip: {
      x: {
        format: "dd/MM/yy",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.7, // Reduce gradient intensity
        opacityFrom: 0.4, // Decrease starting opacity
        opacityTo: 0.4, // Decrease ending opacity
        stops: [68],
      },
    },
    colors: ["#7FC5E3", "#BBE49E"], // Set colors for lines
    legend: {
      position: "top", // Set legend position to top
      horizontalAlign: "right", // Align legend horizontally to the right
      offsetX: 0, // Optional: Adjust horizontal offset
      offsetY: 0, // Optional: Adjust vertical offset
    },
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={280}
        />
      </div>
    </div>
  );
};

export default CompanyGraph;
