import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

const BarChart: React.FC<any> = ({monthlyData}) => {
  // console.log("Data is Graph 3: ",monthlyData);
  const series = React.useMemo(() => {
    return monthlyData
      ? monthlyData
      : [
        {
          name: 'Super Partner',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: 'Mini Partner',
          data: [0, 0, 0, , 0, 0, 0, 0, 0, 0, 0, 0],
        },
        ];
  }, [monthlyData]);
  // const series = [
  //   {
  //     name: 'Super Partner',
  //     data: [800, 850, 600, 450, 700, 600, 650, 600, 500, 800, 750, 600],
  //   },
  //   {
  //     name: 'Mini Partner',
  //     data: [500, 400, 450, 300, 400, 350, 300, 250, 200, 500, 450, 400],
  //   },
  // ];

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 380,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '44%',
        borderRadius: 5, // Apply radius only to the top of the bars
        borderRadiusApplication: 'end', // Apply radius only to the end of the bars (top for vertical bars)
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 0,
      colors: ['transparent'],
    },
    
    xaxis: {
      categories: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ],
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: 'Inter, sans-serif',
          fontWeight: '400',
          colors: ['#344054']
        }
      },
    },
    yaxis: {
      tickAmount: 7,
      title: {
        // text: 'Value',
      },
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: 'Inter, sans-serif',
          fontWeight:"400",
          colors: ['#475467'] // You can customize the color of the labels here
        }
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        }
      }
    },
    colors: ['#7fc5e3', '#bbe49e'],
    legend: {
      position: 'top', // Position legend at the top
      horizontalAlign: 'right', // Align legend horizontally to the right
      offsetX: 0, // Optional: Adjust horizontal offset
      offsetY: 0  // Optional: Adjust vertical offset
    },
 
  };

  return (
    <div id="chart" style={{width:"100%"}}>
      <Chart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
      <p className='month'>Month</p>
    </div>
  );
};

export default BarChart;
