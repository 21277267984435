import React, { useState, useEffect } from "react";
import type { TableColumnsType, TableProps } from "antd";
import { Table, Typography, Tooltip } from "antd";
import { useAuthContext } from "../../context/auth-context.tsx";
import useOpenStats from "../../hooks/use-stats.tsx";

type OnChange = NonNullable<TableProps<DataType>["onChange"]>;

type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

interface DataType {
  key: string;
  company: string;
  referred: string;
  payout: string;
  amount: string;
  total: string;
  status: any;
  view: any;
}

const StatusBtn: React.FC<{ item: string }> = ({ item }) => {
  const { Text } = Typography;
  return (
    <Text
      style={
        item === "Unpaid" || item === "Declined" || item === "Pending"
          ? { backgroundColor: "#FFFAEB", color: "#B54708" }
          : { backgroundColor: "#ECFDF3", color: "#027A48" }
      }
      className="text-[14px] font-medium w-auto rounded-[16px] p-1 px-3"
    >
      {item}
    </Text>
  );
};

const ButtonComponent: React.FC<{ setIsViewOpen: (open: boolean) => void }> = ({
  setIsViewOpen,
}) => {
  return (
    <div className="flex items-center justify-end">
      <button
        onClick={() => setIsViewOpen(true)}
        className="font-semibold text-[#101828] hover:text-[#004EEB]"
      >
        View
      </button>
    </div>
  );
};

const TableSection: React.FC<{
  setIsViewOpen: any;
  isShow: boolean;
  payouts: any;
}> = ({ setIsViewOpen, isShow, payouts }) => {
  
  const initialState: Values = { from: "", to: "" };
  const [sortedInfo, setSortedInfo] = useState<Sorts>({});
  const [values, setValues] = useState<Values | undefined>(initialState);
  const { getStats } = useOpenStats();
  const { user } = useAuthContext();
  
  // useEffect(() => {
  //   getStats(values, user?.user_id && user);
  // },[user]);

  const handleChange: OnChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter as Sorts);
  };

  // console.log("Payouts: ",payouts);

  const columns: TableColumnsType<DataType> = [
    {
      title: (
        <span className="flex items-center gap-2">
          Date
          {/* <img
            src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
            alt=""
          /> */}
        </span>
      ),
      dataIndex: "date",
      key: "date",
      className: "dateSelect",
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="Payments made may take up to 3-5 days to reflect on your bank account. "
        >
          <span className="flex items-center gap-2">Company</span>
        </Tooltip>
      ),
      dataIndex: "company",
      key: "company",
    },
    {
      title: (
        <span className="flex items-center gap-2">
          Revenue Event
          {/* <img
            src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
            alt=""
          /> */}
        </span>
      ),
      dataIndex: "event",
      key: "event",
    },
    {
      title: (
        <span className="flex items-center gap-2">
          Revenue Amount
          {/* <img
            src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
            alt=""
          /> */}
        </span>
      ),
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: (
        <span className="flex items-center gap-2">
          Commission Percent
          {/* <img
            src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
            alt=""
          /> */}
        </span>
      ),
      dataIndex: "percent",
      key: "percent",
    },
    {
      title: (
        <span className="flex items-center gap-2">
          Commission amount
          {/* <img
            src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
            alt=""
          /> */}
        </span>
      ),
      dataIndex: "c_amount",
      key: "c_amount",
    },
    {
      title: (
        <Tooltip placement="top" title="Payments made may take up to 3-5 days to reflect on your bank account. ">
          <span className="flex items-center gap-2">
            Status
            <img
              src="https://ik.imagekit.io/8extk8sjo/Icon%20(36).svg?updatedAt=1711585625996"
              alt=""
            />
            {/* <img
              src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
              alt=""
            /> */}
          </span>
        </Tooltip>
      ),
      dataIndex: "View",
      key: "View",
    },
  ];

  return isShow ? (
    <Table
    className="partnerTable edits"
      columns={columns}
      dataSource={payouts}
      onChange={handleChange}
      pagination={false}
    />
  ) : null;
};



export default TableSection;
