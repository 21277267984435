// @ts-nocheck

import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { usersApi } from "../services/user.ts";
import { toast } from "react-toastify";
import axiosClient from "../utils/axios.tsx";

const HANDLERS = {
  INITIALIZE: "INITIALIZE",
  SIGN_IN: "SIGN_IN",
  SIGN_OUT: "SIGN_OUT",
  UPDATE_USER: "UPDATE_USER",
};

const initialState: {
  user: null | User;
  isAuthenticated: boolean;
  isLoading: boolean;
} = {
  isAuthenticated: Cookies.get("x-super-patner-auth-token") ? true : false,
  // isAuthenticated: Cookies.get("x-affiliate-auth-token") ? true : false,
  isLoading: true,
  user: null,
};

const handlers = {
  [HANDLERS.INITIALIZE]: (state, action) => {
    const user = action.payload;

    return {
      ...state,
      ...(user
        ? {
            isAuthenticated: true,
            isLoading: false,
            user,
          }
        : {
            isLoading: false,
            isAuthenticated: user ? true : null,
          }),
    };
  },
  [HANDLERS.SIGN_IN]: (state, action) => {
    const user = action.payload;
    return {
      ...state,
      isAuthenticated: user !== null ? true : false,
      user,
    };
  },
  [HANDLERS.SIGN_OUT]: (state) => ({ ...state, isAuthenticated: false }),
  [HANDLERS.UPDATE_USER]: (state, action) => {
    return {
      ...state,
      user: action.payload,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContext = createContext({
  ...initialState,
  googleSignIn: (token) => Promise.resolve(),
  signIn: (email, password) => Promise.resolve(),
  verifiedLogIn: (user, token) => Promise.resolve(),
  signOut: () => Promise.resolve(),
  updateUser: (userData) => {},
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const [token, setToken] = useState("");

  // const signIn = async (email, password) => {
  //   try {
  //     const user = await usersApi.loginUser({ email, password });
  //     if (user?.token) {
  //       if (user?.role === "AFFILIATES") {
  //         if (user?.is_active === true) {
  //           toast.success("Successfully logged in");
  //           Cookies.set("x-affiliate-auth-token", user.token);
  //           Cookies.set("affiliate-user-id", user?.user_id);
  //           setToken(user?.token);
  //           navigate("/dashboard");
  //           dispatch({ type: HANDLERS.SIGN_IN, payload: user.user });
  //         } else if (user?.is_active === false) {
  //           navigate("/deactivated");
  //         }
  //       } else if (user?.role === "ADMIN") {
  //         toast.error("These credentials are not compatible with this panel");
  //       } else if (user?.role === "EMPLOYEE") {
  //         toast.error("These credentials are not compatible with this panel");
  //       }
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  // const signIn = async (email, password) => {
  //   try {
  //     const user = await usersApi.loginUser({ email, password });
  //     if (user?.token) {
  //       if (user?.role === "AFFILIATES") {
  //         if (user?.is_active === true) {
  //           toast.success("Successfully logged in");
  //           Cookies.set("x-affiliate-auth-token", user.token);
  //           Cookies.set("affiliate-user-id", user?.user_id);
  //           setToken(user?.token);
  //           navigate("/dashboard");
  //           dispatch({ type: HANDLERS.SIGN_IN, payload: user.user });
  //         } else {
  //           navigate("/deactivated");
  //         }
  //       } else if (user?.role === "ADMIN" || user?.role === "EMPLOYEE") {
  //         toast.error("These credentials are not compatible with this panel");
  //       }
  //     }
  //   } catch (err) {
  //     if (err.response?.data?.message === 'Account removed') {
  //       navigate("/deactivated");
  //     }
  //     else {
  //       console.error(err);
  //       toast.error(err.response?.data?.message || "An error occurred");
  //     }
  //   }
  // };

  const signIn = async (email, password) => {
    try {
      const user = await usersApi.loginUser({ email, password });
      if (user?.token) {
        if (user?.role === "SUPER_PARTNER") {
          if (user?.is_active === true) {
            toast.success("Successfully logged in");
            Cookies.set("x-super-partner-auth-token", user.token);
            Cookies.set("super-partner-user-id", user?.user_id);
            // Cookies.set("x-affiliate-auth-token", user.token);
            // Cookies.set("affiliate-user-id", user?.user_id);
            setToken(user?.token);
            navigate("/dashboard");
            dispatch({ type: HANDLERS.SIGN_IN, payload: user.user });
            axiosClient()
              .patch("users/last-login", {
                email: user?.email,
                token: user?.token,
              })
              .catch((err) => console.error(err));
          } else {
            navigate("/deactivated");
          }
        } else if (
          user?.role === "ADMIN" ||
          user?.role === "AFFILIATES" ||
          user?.role === "EMPLOYEE" ||
          user?.role === "MINI_PARTNER"
        ) {
          toast.error("These credentials are not compatible with this panel");
        }
      }
    } catch (err) {
      if (err.message === "ACCOUNT_REMOVED") {
        navigate("/deactivated");
      } else {
        console.error(err);
        // The toast error is already handled in the User class
      }
    }
  };
  const verifiedLogIn = async (user, token) => {
    try {
      toast.success("Successfully logged in");
      Cookies.set("x-super-partner-auth-token", user?.token);
      Cookies.set("super-partner-user-id", user?.user_id);
      // Cookies.set("x-affiliate-auth-token", user.token);
      // Cookies.set("affiliate-user-id", user?.user_id);
      setToken(user?.token);
      navigate("/dashboard");
      dispatch({ type: HANDLERS.SIGN_IN, payload: user.user });
      axiosClient()
        .patch("users/last-login", {
          email: user?.email,
          token: user?.token,
        })
        .catch((err) => console.error(err));
    } catch (err) {
      console.error(err);
    }
  };

  const googleSignIn = async (access_token) => {
    try {
      const user = await usersApi.googleLogin({ "google-token": access_token });
      if (user?.token) {
        if (user?.role === "SUPER_PARTNER") {
          if (user?.is_active === true) {
            toast.success("Successfully logged in");
            Cookies.set("x-super-partner-auth-token", user.token);
            Cookies.set("super-partner-user-id", user?.user_id);
            // Cookies.set("x-affiliate-auth-token", user.token);
            // Cookies.set("affiliate-user-id", user?.user_id);
            setToken(user?.token);
            navigate("/dashboard");
            dispatch({ type: HANDLERS.SIGN_IN, payload: user.user });
            axiosClient()
              .patch("users/last-login", {
                email: user?.email,
                token: user?.token,
              })
              .catch((err) => console.error(err));
          } else if (user?.is_active === false) {
            navigate("/deactivated");
          }
        } else if (
          user?.role === "ADMIN" ||
          user?.role === "AFFILIATES" ||
          user?.role === "EMPLOYEE" ||
          user?.role === "SUPER_PARTNER"
        ) {
          toast.error("These credentials are not compatible with this panel");
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const initialize = async () => {
    // if (token) {
    try {
      const user = await usersApi.getUserByToken();
      if (user) {
        dispatch({
          type: HANDLERS.INITIALIZE,
          payload: user,
        });
      }
    } catch (err) {
      // Cookies.remove("x-affiliate-auth-token");
      console.error(err);
      // navigate("/");
      // dispatch({
      //   type: HANDLERS.INITIALIZE,
      //   payload: null,
      // });
    }
    // }
  };

  const updateUser = (userData) => {
    dispatch({ type: HANDLERS.UPDATE_USER, payload: userData });
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const signOut = () => {
    Cookies.remove("x-super-partner-auth-token");
    Cookies.remove("super-partner-user-id");
    // Cookies.remove("x-affiliate-auth-token");
    // Cookies.remove("affiliate-user-id");
    dispatch({ type: HANDLERS.SIGN_OUT });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        verifiedLogIn,
        googleSignIn,
        signOut,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;
export const useAuthContext = () => useContext(AuthContext);
