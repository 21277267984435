import React from "react";
import ReactApexChart from "react-apexcharts";

const LineGraph: React.FC<any> = ({monthlyData}) => {
  // console.log("Data in Graph 2: ", monthlyData);
  const series = React.useMemo(() => {
    return monthlyData
      ? monthlyData
      : [
        {
          name: 'Partners Link',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] // Data from Jan to Dec
        },
        {
          name: 'Personal Link',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] // Data from Jan to Dec
        }
      ];
  }, [monthlyData]);

  // const [series] = React.useState([
  //   {
  //     name: 'Partners Link',
  //     data: [31, 40, 28, 51, 42, 109, 140, 31, 40, 28, 51, 42] // Data from Jan to Dec
  //   },
  //   {
  //     name: 'Personal Link',
  //     data: [11, 32, 45, 32, 34, 52,  90, 31, 40, 28, 51, 42] // Data from Jan to Dec
  //   }
  // ]);

  const [options] = React.useState({
    chart: {
      height: 350,
      type: 'area'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [2, 2], // Make the curve lines thinner
      curve: 'smooth'
    },
    yaxis: {
      title: {
        text: undefined,
      },
      tickAmount: 4,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec", // From Jan to Dec
      ],
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy'
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0.7, // Reduce gradient intensity
        opacityFrom: 0.3, // Decrease starting opacity
        opacityTo: 0.03, // Decrease ending opacity
        stops: [0, 100]
      }
    },
    colors: ['#7FC5E3', '#BBE49E'], // Set colors for lines
    legend: {
      position: 'top', // Set legend position to top
      horizontalAlign: 'right', // Align legend horizontally to the right
      offsetX: 0, // Optional: Adjust horizontal offset
      offsetY: 0  // Optional: Adjust vertical offset
    },
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="area" height={224} />
      </div>
    </div>
  );
};

export default LineGraph;
