import React, { useEffect, useState } from "react";
import { Row, Col, Button, Dropdown } from "antd";
import { Logo } from "../../components/index.ts";
import { NAV_ITEMS } from "../../constants/data.ts";
import Logos from "../../../src/assets/images/logos.png";
import Logout from "../../../src/assets/images/logout.png";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAuthContext } from "../../context/auth-context.tsx";
import { useSelector } from "react-redux";
import { RootState } from "@reduxjs/toolkit/query";
import Dark from "../../assets/images/star.png"
import Sun from "../../assets/images/sun.png"
import Notification from "../../assets/images/notifications.png"
import Green from "../../assets/images/green.png"
import Orange from "../../assets/images/orange.png"
import Red from "../../assets/images/red.png"

import G from "../../assets/images/g.png"
import Y from "../../assets/images/y.png"
import R from "../../assets/images/r.png"
import Dlogo from "../../assets/images/darklogo.png"

const Header = (): JSX.Element => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [number, setNumber] = useState(0);
  const { pathname } = useLocation();
  const handleNavigate = (item: any, index: number) => {
    navigate(item.url);
  };
  const payouts = useSelector((state: RootState) => state.payouts.payouts);
  const [theme, setTheme] = useState("light");
  const { user, signOut } = useAuthContext();

  // Handle theme change
  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  useEffect(() => {
    // Load theme from localStorage if available
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  useEffect(() => {
    // Update the body class based on the theme
    document.body.className = theme;
  }, [theme]);

  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={() => navigate("/settings")}
          className="w-full border-b bss border-[#EAECF0] font-sans gap-3 p-3 flex"
        >
          <div className="w-[44px] h-[44px] rounded-[100%] overflow-hidden bg-gray-100 cursor-pointer" style={{minWidth:"44px", maxWidth:"44px"}}>
            {user?.profile_image ? (
              <img
                src={user?.profile_image}
                alt=""
                className="object-cover"
                style={{ width: "50px", height: "50px" }}
              />
            ) : (
              <div className="flex items-center justify-center">
                <p className="text-[28px] capitalize font-medium text-[#667085] ">
                  {user?.name?.charAt(0)}
                </p>
              </div>
            )}
          </div>
          <div className="grid w-full">
            <span className="text-[#344054] text-[14px] capitalize font-semibold">
              {user?.name}
            </span>
            <span className="-mt-1 w-full">{user?.email}</span>
          </div>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div className="p-3 py-4 bss">
          <div
            className="flex items-center justify-start w-full rounded-md text-white font-sans gap-3"
          >
            {theme === "light" ? <img
              src={Dark}
              alt=""
            /> : <img
              src={Sun}
              alt=""
            />}
            <span className="text-[#344054] font-medium text-sm" onClick={toggleTheme}>
              {theme === "light" ? "Dark Mode" : "Light Mode"}
            </span>
          </div>
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div className="p-3 py-4">
          <div
            onClick={() => {
              navigate("/");
              signOut();
            }}
            className="flex items-center justify-start w-full rounded-md text-white font-sans gap-3"
          >
            <img
              src="https://ik.imagekit.io/8extk8sjo/log-out-01.svg?updatedAt=1710827754232"
              alt=""
              className="desktop"
            />
            <img src={Logout} alt="" className="mobile" />
            <span className="text-[#344054] font-medium text-sm">Log out</span>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setNumber(number + 1);
  }, []);


  const notifications = [
    {
      id: 1,
      icon: Green,
      dark: G,
      title: "Payouts Approved",
      message: "3 of your payouts have been approved.",
      time: "2 mins ago",
    },
    {
      id: 2,
      icon: Orange,
      dark: Y,
      title: "Customer churned",
      message: "One of your customers has churned.",
      time: "2 mins ago",
    },
    {
      id: 3,
      icon: Red,
      dark: R,
      title: "System updated link",
      message: "One of your customers has churned.",
      time: "2 mins ago",
    },
  ];

  return (
    <div >
      <div className="w-full h-[72px] flex justify-center bg-white border-b border-[#EAECF0] font-sans adminHeader">
        <div className="container h-[72px] bg-[#FFFFFF] border-b border-[#EAECF0] fixed z-50">
          <Row>
            <Col span={3} className="flex items-center justify-start h-[72px]">
              <Logo />
              <img src={Dlogo} alt="" className="w-[180px] mobile" />
            </Col>
            <Col span={16} className="h-[72px] flex items-center gap-3 pl-1 headers">
              {NAV_ITEMS?.map((item: any, index: number) => (
                <Button
                  key={index}
                  onClick={() => handleNavigate(item, index)}
                  className={`h-[44px] shadow-none border-none font-sans p-[8px, 12px, 8px, 12px] rounded-[6px] font-semibold text-[16px] text-[#344054] flex items-center gap-2 ${pathname === item.url && "bg-[#EFF4FF] sds"
                    }`}
                >
                  {item?.label}
                  {item?.isCount && (
                    <div className="rounded-[16px] bg-[#F2F4F7] px-3 font-sans">
                      {/* {user?.is_verified ? payouts?.length : 0} */}
                      {user?.is_verified ? (payouts?.ownCommissions?.length || 0) + (payouts?.miniPartnerCommissions?.length || 0) : 0}
                    </div>
                  )}
                </Button>
              ))}
            </Col>
            <Col span={5} className="flex items-center justify-end gap-6">
              <Col style={{ position: "relative" }}>
                <img src={Notification} alt="" onClick={() => setShow(!show)} style={{cursor:"pointer"}} />
                {show && <Col className="notificationBoxed">
                  {notifications.map((notification) => (
                    <Row className="" key={notification.id} style={{ borderBottom: "1px solid #F2F4F7", marginBottom: "16px" }}>
                      <Col md={4}>
                        <Col>
                          <img src={notification.icon} alt="" className="lightimag" />
                          <img src={notification.dark} alt="" className="darkimag" />
                        </Col>
                      </Col>
                      <Col md={15}>
                        <Col>
                          <h6>{notification.title}</h6>
                          <p>{notification.message}</p>
                          <Col className="dismiss Btn">
                            <span>Dismiss</span>
                            <Link to="">View all</Link>
                          </Col>
                        </Col>
                      </Col>
                      <Col md={5}>
                        <Col>
                          <h5>{notification.time}</h5>
                        </Col>
                      </Col>
                    </Row>
                  ))}
                  <Col className="textRight">
                    <h5>Dismiss all</h5>
                  </Col>
                </Col>}
              </Col>
              <div className="w-[44px] h-[44px] rounded-[50px] overflow-hidden bg-gray-100 cursor-pointer">
                <Dropdown
                  menu={{ items }}
                  placement="bottomRight"
                  className="border-b border-[#EAECF0] shadow-none"
                >
                  {user?.profile_image ? (
                    <img
                      src={user?.profile_image}
                      alt=""
                      className="object-cover w-full h-full"
                    />
                  ) : (
                    <div className="flex items-center justify-center h-full w-full">
                      <p className="text-[28px] capitalize font-medium text-[#667085]">
                        {user?.name?.charAt(0)}
                      </p>
                    </div>
                  )}
                </Dropdown>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Header;
