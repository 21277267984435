import axios from "axios";
import Cookies from "js-cookie";

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export function axiosClient() {
  let defaultOptions = {
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      accept: "application/json",
      token: `${Cookies.get("x-super-partner-auth-token")}`,
      // token: `${Cookies.get("x-affiliate-auth-token")}`,
    },
  };

  let instance = axios.create(defaultOptions);

  instance.interceptors.request.use(function (config) {
    config.headers.common = {
      "x-auth-token": `${Cookies.get("x-super-partner-auth-token")}`,
      // "x-auth-token": `${Cookies.get("x-affiliate-auth-token")}`,
    };

    return config;
  });

  return instance;
}

export default axiosClient;
