import React from "react";
import { Table, Tag, Space, Button } from "antd";

const DashboardTable: React.FC = () => {
    const dataSource = [
        {
            key: '1',
            company: 'Test Corp 5',
            referredOn: '04/09/2024',
            status: 'Unpaid',
            lastPayout: 'NA',
            referralType: 'Partner Referral',
        },
        {
            key: '2',
            company: 'Wall Corp',
            referredOn: '04/09/2024',
            status: 'Paid',
            lastPayout: 'NA',
            referralType: 'Personal Referral',
        },
        {
            key: '3',
            company: 'Test Lead 80',
            referredOn: '04/09/2024',
            status: 'Unpaid',
            lastPayout: '02/09/2024',
            referralType: 'Partner Referral',
        },
    ];

    const columns = [
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
            render: (text: string, record: any) => (
                <div className="firstTd">
                <Space size="middle" >
                    {text}
                  
                </Space>
                  <Tag color={record.referralType === 'Partner Referral' ? 'blue' : 'green'}>
                  {record.referralType}
              </Tag>
              </div>
            ),
        },
        {
            title: 'Referred On',
            dataIndex: 'referredOn',
            key: 'referredOn',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => (
                <Tag className="colors" color={status === 'Paid' ? 'green' : 'orange'}>
                    {status}
                </Tag>
            ),
        },
        {
            title: 'Last Payout',
            dataIndex: 'lastPayout',
            key: 'lastPayout',
        },
        {
            title: '',
            key: 'action',
            render: () => <Button type="link">View</Button>,
        },
    ];

    return (
        <>
            <div className="rounded-[12px] border border-[#EAECF0] bg-white overflow-hidden mt-3 partnerBox" style={{ width:"100%",   boxShadow: "rgba(16, 24, 40, 0.06) 0px 1px 2px 0px, rgba(16, 24, 40, 0.1) 0px 1px 3px 0px"}}>
                <Table
                    className="partnerTable Dashboard"
                    style={{ width: "100%" }}
                    dataSource={dataSource}
                    columns={columns}
                />
                <div className="w-full bg-white h-20 items-center  px-4  flex justify-between gap-4">
                    <span className="text-[#344054] text-[14px] font-medium ">
                        Page 1 of 10
                    </span>

                    <div className="flex items-center gap-3">
                        <button
                            style={{
                                boxShadow: "0px 1px 2px 0px #1018280D",
                            }}
                            className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
                        //   disabled={currentPage === 1}
                        //   onClick={() => handlePageChange(currentPage - 1)}
                        >
                            Previous
                        </button>
                        <button
                            style={{
                                boxShadow: "0px 1px 2px 0px #1018280D",
                            }}
                            className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
                        //   disabled={currentPage === totalPages}
                        //   onClick={() => handlePageChange(currentPage + 1)}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashboardTable;
