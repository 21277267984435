// @ts-ignore
import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  DatePicker,
  Button,
  Input,
  Typography,
  Spin,
  Modal,
} from "antd";
import Card from "./card.tsx";
import Graph from "./graph.tsx";
import { DASHBOARD_BTNS } from "../../constants/data.ts";
import TableSection from "./table-section.tsx";
import type { Dayjs } from "dayjs";
import type { TimeRangePickerProps, DatePickerProps } from "antd";
import dayjs from "dayjs";
import Sidebar from "../../layout/sidebar/view.tsx";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/auth-context.tsx";
import { useSelector } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import useOpenStats from "../../hooks/use-stats.tsx";
import useOpenPayouts from "../../hooks/use-payouts.tsx";
import axiosClient from "../../utils/axios.tsx";
import { RootState } from "../../redux/store.ts";

import Dark from "../../assets/images/copi.png"
import Light from "../../assets/images/url.png"
import RightArrow from "../../assets/images/right_arrow.svg";
import Pic from "../../assets/images/picture.png";
import One from "../../assets/images/one.png";
import Two from "../../assets/images/two.png";
import Three from "../../assets/images/three.png";
import Four from "../../assets/images/four.png";
import Five from "../../assets/images/five.png";
import Six from "../../assets/images/six.png";
import Booking from "./booking.tsx";
import LineGraph from "./linkGraph.tsx";
import BarChart from "./barGraph.tsx";
import CompanyGraph from "./companyGraph.tsx";
import PartnerGraph from "./partnerGraph.tsx";
import ProfileSection from "../../components/profile.tsx";
import Cookies from "js-cookie";
import DashboardTable from "./dashboardTable.tsx";
// import YearPicker from "./yearPicker.tsx";
interface Values {
  from: any;
  to: any;
}
const initialState: Values = { from: "", to: "" };

const Main = () => {
  const navigate = useNavigate();
  const { Text } = Typography;
  const [isBooking, setIsBooking] = useState(false);
  const [userCompanies, setUserCompanies] = useState([]);
  const [year1, setYear1] = useState(new Date().getFullYear());
  const [year2, setYear2] = useState(new Date().getFullYear());
  const [year3, setYear3] = useState(new Date().getFullYear());
  const [year4, setYear4] = useState(new Date().getFullYear());
  const [year5, setYear5] = useState(new Date().getFullYear());
  const [graphData1, setGraphData1] = useState({});
  const [graphData2, setGraphData2] = useState({});
  const [graphData3, setGraphData3] = useState({});
  const [graphData4, setGraphData4] = useState({});
  const [graphData5, setGraphData5] = useState({});
  const [isGraph1Loading, setIsGraph1Loading] = useState(false);
  const [isGraph2Loading, setIsGraph2Loading] = useState(false);
  const [isGraph3Loading, setIsGraph3Loading] = useState(false);
  const [isGraph4Loading, setIsGraph4Loading] = useState(false);
  const [isGraph5Loading, setIsGraph5Loading] = useState(false);
  const [stats1, setStats1] = useState({});
  const [stats2, setStats2] = useState({});
  const [values, setValues] = useState<Values | undefined>(initialState);
  const { user } = useAuthContext();
  const stats = useSelector((state: RootState) => state.stats.stats);
  const { getStats } = useOpenStats();
  const { getPayouts } = useOpenPayouts();
  const [copied, setCopied] = useState(false);
  const userId = Cookies.get("super-partner-user-id");

  const activity = useSelector((state: RootState) => state.activity.activity);

  // console.log("Date1: ",year1);
  // console.log("Date4: ",year4);

  useEffect(() => {
    if (userId) {
      axiosClient()
        .get(`users-commissions/super-partner/self-stats?userId=${userId}`)
        .then((res) => {
          // console.log("Stats1: ", res.data);
          setStats1(res.data);
        })
        .catch((err) => console.error(err.message));
    }
  }, []);

  useEffect(() => {
    if (userId) {
      axiosClient()
        .get(
          `pipedrive-organisations/super-partner/partner/stats?userId=${userId}`
        )
        .then((res) => {
          // console.log("Stats2: ", res.data);
          setStats2(res.data);
        })
        .catch((err) => console.error(err.message));
    }
  }, []);

  const dateChangeHandler = (value, setState) => {
    if (value) {
      setState(value.year());
    }
  };

  useEffect(() => {
    // console.log("Fetch Year: ", year1);
    if (userId) {
      setIsGraph1Loading(true);
      axiosClient()
        .get(
          `pipedrive-organisations/super-partner/lvc-graph?userId=${userId}&year=${year1}`
        )
        .then((res) => {
          // console.log("Data from graph API: ", res.data);
          setGraphData1(res.data);
        })
        .catch((err) => console.log(err.message))
        .finally(() => setIsGraph1Loading(false));
    }
  }, [year1]);

  useEffect(() => {
    // console.log("Fetch Year: ", year2);
    if (userId) {
      setIsGraph2Loading(true);
      axiosClient()
        .get(
          `pipedrive-organisations/super-partner/graph/personal-partner-leads?userId=${userId}&year=${year2}`
        )
        .then((res) => {
          // console.log("Data from graph API: ", res.data);
          setGraphData2(res.data);
        })
        .catch((err) => console.log(err.message))
        .finally(() => setIsGraph2Loading(false));
    }
  }, [year2]);

  useEffect(() => {
    // console.log("Fetch Year: ", year2);
    if (userId) {
      setIsGraph3Loading(true);
      axiosClient()
        .get(
          `users/super-partner/graph/personal-partner-traffic?userId=${userId}&year=${year3}`
        )
        .then((res) => {
          // console.log("Data from graph API: ", res.data);
          setGraphData3(res.data);
        })
        .catch((err) => console.log(err.message))
        .finally(() => setIsGraph3Loading(false));
    }
  }, [year3]);

  useEffect(() => {
    // console.log("Fetch Year: ", year4);
    if (userId) {
      setIsGraph4Loading(true);
      axiosClient()
        .get(
          `pipedrive-organisations/super-prtner/graph/own-companies-converted?userId=${userId}&year=${year4}`
        )
        .then((res) => {
          // console.log("Data from graph API: ", res.data);
          setGraphData4(res.data);
        })
        .catch((err) => console.log(err.message))
        .finally(() => setIsGraph4Loading(false));
    }
  }, [year4]);

  useEffect(() => {
    // console.log("Fetch Year: ", year4);
    if (userId) {
      setIsGraph5Loading(true);
      axiosClient()
        .get(
          `pipedrive-organisations/super-partner/graph/mini-partner-converted?userId=${userId}&year=${year5}`
        )
        .then((res) => {
          // console.log("Data from PCC graph API: ", res.data);
          setGraphData5(res.data);
        })
        .catch((err) => console.log(err.message))
        .finally(() => setIsGraph5Loading(false));
    }
  }, [year5]);

  const SearchIcon = () => {
    return (
      <img
        src="https://ik.imagekit.io/8extk8sjo/search-lg.svg?updatedAt=1709278920452"
        alt=""
      />
    );
  };

  const CustomBtnIcon = () => {
    return (
      <img
        src="https://ik.imagekit.io/8extk8sjo/Icon%20(27).svg?updatedAt=1709811522884"
        alt=""
        className="w-[15px] h-[15px]"
      />
    );
  };

  const handleCopy = () => {
    const textToCopy = `${
      process.env.REACT_APP_REFERRAL_FORM_DOMAIN || "www.domain.com"
    }?ref=${
      user?.user_id ? user?.user_id : "no ref"
      // user?.ref_link ? user?.ref_link : "no ref"
    }`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  useEffect(() => {
    if(user){
      getStats(values, user?.user_id && user);
      getPayouts(user?.user_id && user); //we don't need to pass date here.
    }
  }, [values, user]);

  useEffect(() => {
    setUserCompanies(stats?.userCompanies);
  }, [stats]);

  const TOTAL_CARDS = [
    {
      label: "Net Partner Earnings",
      image: One,
      counts: ` $${
        (user?.is_verified && stats1?.netPartnerEarnings?.toFixed(2)) || "0"
      }`,
    },
    {
      label: "Net Unpaid Commission",
      image: Two,
      counts: ` $${
        (user?.is_verified && stats1?.netUnpaidCommission?.toFixed(2)) || "0"
      }`,
    },
    {
      label: "Net Paid Commission",
      image: Three,
      counts: `$${
        (user?.is_verified && stats1?.netPaidCommission?.toFixed(2)) || "0"
      }`,
    },
    {
      label: "Total Earnings",
      image: Four,
      counts: `$${
        (user?.is_verified && stats1?.totalEarnings?.toFixed(2)) || "0"
      }`,
    },
  ];
  // console.log("Stats: ", stats);
  const TOTAL_CARDS2 = [
    {
      label: "Partners",
      image: Five,
      counts: stats2?.partners || 0,

      // counts: 0,
    },
    {
      label: "Partner Conversion Rate",
      image: Six,
      counts: `${stats2?.partnerConversionRate?.toFixed(2)}%` || "0%",
    },
    {
      label: "Personal Conversion Rate",
      image: Six,
      counts: (user?.is_verified && `${stats2?.personalConversionRate?.toFixed(2)}%`) || "0%",
    },
  ];

  const handleSearch = (value) => {
    let filteredData;
    if (value === "") {
      filteredData = stats?.userCompanies;
    } else {
      filteredData = stats?.userCompanies?.filter((x) => {
        return x?.Name?.toLowerCase().includes(value?.toLowerCase());
      });
    }
    setUserCompanies(filteredData);
  };

  return (
    <>
      {user?.name ? (
        <>
          <div className="container pt-28 ">
            {!user?.is_verified && (
              <>
                <Text className="text-[#101828] text-[30px] font-semibold ">
                  Welcome, <span className="capitalize">{user?.name}</span>
                </Text>
                <div className="w-full p-[16px] bg-[#FFFCF5] border mt-5 border-[#FEC84B] rounded-[12px] flex items-start">
                  <img
                    src="https://ik.imagekit.io/8extk8sjo/Icon%20(35).svg?updatedAt=1711530043666"
                    alt=""
                  />

                  <div className="grid pl-3">
                    <Text className="text-[#B54708] -mt-1 text-[14px] font-semibold">
                      Your account is pending approval from Glovendor.
                    </Text>
                    <Text className="text-[#B54708] text-[14px] font-normal">
                      You will receive an email once your account is activated.
                      If you would like to learn more about the Glovendor
                      Partner Program please book a support call using the link
                      below.
                    </Text>
                    <Text
                      onClick={() => setIsBooking(true)}
                      className="text-[#DC6803] cursor-pointer text-[14px] flex gap-2 items-center font-semibold mt-2"
                    >
                      <text className="underline">Book a meeting</text>
                      <div>
                        <img src={RightArrow} />
                      </div>
                    </Text>
                  </div>
                </div>
              </>
            )}

            {user?.is_verified && (
              <>
                <div className="grid">
                  <Text className="text-[#101828] text-[30px] font-semibold capitalize headingText">
                    Welcome back, {user?.name}
                  </Text>
                  <Text className="text-[#475467] text-[16px] headingContent" >
                    Use your affiliate link to send leads towards Glovendor.
                  </Text>
                </div>
                <div className="flex items-center mt-4 copyBtn">
                  <Input
                    className="w-[380px] h-[44px] rounded-l-[8px] placeholder:font-bold placeholder:text-[#667085] font-semibold rounded-r-none border border-[#D0D5DD]"
                    placeholder=""
                    value={`${
                      process.env.REACT_APP_REFERRAL_FORM_DOMAIN ||
                      "www.domain.com"
                    }?ref=${
                      user?.user_id ? user?.user_id : "no ref"
                      // user?.ref_link ? user?.ref_link : "no ref"
                    }`}
                  />
                  <Button
                    type="primary"
                    // icon={<CustomBtnIcon />}
                    style={{
                      border: "1px solid #D0D5DD",
                      borderRight: "1px solid #D0D5DD",
                      borderBottom: "1px solid #D0D5DD",
                      borderRadius: "0px, 8px, 8px, 0px",
                      display: "flex",
                      alignItems: "center"
                    }}
                    onClick={handleCopy}
                    className="flex-none bg-transparent shadow-none h-[44px] text-[#344054] font-bold rounded-r-[8px] rounded-l-none bg-white"
                  >
                      <img src={Dark} alt="" className="lightcopy" />
                    <img src={Light} alt="" className="darkcopy" />
                    {copied ? "Copied!" : "Copy"}
                  </Button>
                </div>
              </>
            )}

            <div className="grid mt-5">
              <Text className="text-[#101828] font-semibold text-[24px]">
                Your Earnings
              </Text>
              <Text className="text-[#475467] text-[16px] font-normal headingContent">
                {!user?.is_verified
                  ? "Use your affiliate link to send leads towards Glovendor."
                  : "Overview of your earnings."}
              </Text>
            </div>

            <Row gutter={30}>
              <Col md={6}>
                {TOTAL_CARDS?.map((item: any, index: number) => (
                  <Col key={index} span={24} className="mt-7 cardBoxed">
                    <Card
                      label={item?.label}
                      image={item.image}
                      count={item?.counts}
                    />
                  </Col>
                ))}
              </Col>
              <Col span={18} className="mt-8 overflow-hidden">
                <Col
                className="dashboardright"
                  style={{
                    boxShadow: "0px 1px 2px 0px #1018280F",
                    background: "#fff",
                    borderRadius: "12px",
                    padding: "0",
                  }}
                >
                  <Col className="graphHeader">
                    <Col>
                      <h3>Your referrals</h3>
                      <h6>
                        {graphData1?.totalReferred
                          ? graphData1?.totalReferred
                          : 0}
                      </h6>
                    </Col>

                    <DatePicker
                      className="dateRange"
                      picker="year"
                      minDate={dayjs(new Date(2022, 0, 1))}
                      maxDate={dayjs(new Date())}
                      value={dayjs().year(year1)}
                      onChange={(value) => dateChangeHandler(value, setYear1)}
                      disabled={isGraph1Loading}
                    />
                  </Col>
                  {isGraph1Loading ? (
                    <div className="flex items-center justify-center py-[10.7rem]">
                      <Spin />
                    </div>
                  ) : (
                    <Graph
                      data={graphData1?.monthlyData}
                      isShow={user?.is_verified}
                    />
                  )}
                </Col>
              </Col>
            </Row>

            <Row gutter={30}>
              <Col md={18}>
                <Col className="linkBoxed">
                  <Col className="graphHeader">
                    <Col style={{ display: "flex" }}>
                      <Col style={{ marginRight: "30px" }}>
                        <h3>Personal Leads</h3>
                        <h6>
                          {graphData2?.personalLeads
                            ? graphData2?.personalLeads
                            : 0}
                        </h6>
                      </Col>
                      <Col>
                        <h3>Partners Leads</h3>
                        <h6>{graphData2?.partnerLeads
                            ? graphData2?.partnerLeads
                            : 0}</h6>
                      </Col>
                    </Col>
                    <DatePicker
                      className="dateRange"
                      picker="year"
                      minDate={dayjs(new Date(2022, 0, 1))}
                      maxDate={dayjs(new Date())}
                      value={dayjs().year(year2)}
                      onChange={(value) => dateChangeHandler(value, setYear2)}
                      disabled={isGraph2Loading}
                    />
                  </Col>
                  {isGraph2Loading ? (
                    <div className="flex items-center justify-center py-[7rem]">
                      <Spin />
                    </div>
                  ) : (
                    <LineGraph monthlyData={graphData2?.graphData} />
                  )}
                </Col>
              </Col>
              <Col md={6}>
                {TOTAL_CARDS2?.map((item: any, index: number) => (
                  <Col key={index} span={24} className="mt-7 rightBoxed" style={{paddingRight:"0"}}>
                    <Card
                      label={item?.label}
                      image={item.image}
                      count={item?.counts}
                    />
                  </Col>
                ))}
              </Col>
            </Row>
            <Row>
              <Col span={24} className="my-8 mb-0" style={{ marginTop: "0" }}>
                <Col className="linkBoxed bars" style={{ marginTop: "0" }}>
                  <Col className="graphHeader">
                    <Col style={{ display: "flex" }}>
                      <Col style={{ marginRight: "60px" }}>
                        <h3>Personal Link Traffic</h3>
                        <h6>
                          {graphData3?.personalTraffic ? graphData3?.personalTraffic : 0} <span>Visits</span>
                        </h6>
                      </Col>
                      <Col style={{ marginLeft: "60px" }}>
                        <h3>Partner Link Traffic</h3>
                        <h6>
                        {graphData3?.personalTraffic ? graphData3?.partnerTraffic : 0} <span>Visits</span>
                        </h6>
                      </Col>
                    </Col>
                    <DatePicker
                      className="dateRange"
                      picker="year"
                      minDate={dayjs(new Date(2022, 0, 1))}
                      maxDate={dayjs(new Date())}
                      value={dayjs().year(year3)}
                      onChange={(value) => dateChangeHandler(value, setYear3)}
                      disabled={isGraph3Loading}
                    />
                  </Col>
                  {isGraph3Loading ? (
                    <div className="flex items-center justify-center py-[10.1rem]">
                      <Spin />
                    </div>
                  ) : (
                    <BarChart monthlyData={graphData3?.graphData} />
                  )}
                </Col>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Col
                  className="linkBoxed "
                  style={{ marginTop: "0", marginRight: "15px" }}
                >
                  <Col className="graphHeader">
                    <Col style={{ paddingLeft: "25px" }}>
                      <h3>Own Companies Converted</h3>
                      <h6>
                        {graphData4?.totalCompanies
                          ? graphData4?.totalCompanies
                          : 0}
                      </h6>
                    </Col>
                    <DatePicker
                      className="dateRange"
                      picker="year"
                      minDate={dayjs(new Date(2022, 0, 1))}
                      maxDate={dayjs(new Date())}
                      value={dayjs().year(year4)}
                      onChange={(value) => dateChangeHandler(value, setYear4)}
                      disabled={isGraph4Loading}
                    />
                  </Col>
                  {isGraph4Loading ? (
                    <div className="flex items-center justify-center py-[9rem]">
                      <Spin />
                    </div>
                  ) : (
                    <CompanyGraph monthlyData={graphData4?.monthlyData} />
                  )}
                </Col>
              </Col>
              <Col md={12}>
                <Col
                  className="linkBoxed "
                  style={{ marginTop: "0", marginLeft: "15px" }}
                >
                  <Col className="graphHeader">
                    <Col style={{ paddingLeft: "25px" }}>
                      <h3>Partner Companies Converted</h3>
                      <h6>
                        {graphData5?.totalCompanies
                          ? graphData5?.totalCompanies
                          : 0}
                      </h6>
                    </Col>
                    <DatePicker
                      className="dateRange"
                      picker="year"
                      minDate={dayjs(new Date(2022, 0, 1))}
                      maxDate={dayjs(new Date())}
                      // value={dayjs(new Date())}
                      value={dayjs().year(year5)}
                      onChange={(value) => dateChangeHandler(value, setYear5)}
                      disabled={isGraph5Loading}
                    />
                  </Col>
                  {isGraph5Loading ? (
                    <div className="flex items-center justify-center py-[9rem]">
                      <Spin />
                    </div>
                  ) : (
                    <PartnerGraph monthlyData={graphData5?.monthlyData} />
                  )}
                </Col>
              </Col>
            </Row>
            <Row>
              <DashboardTable/>
            </Row>
            {/* <Row>
              <h4 className="recentH4">Recent Activity</h4>
              <Col md={24}>
                <Col className="linkBoxed" style={{ marginTop: "0" }}>
                  <Row className="p-6">
                    <Col md={12}>
                      <ul>

                        {data.map((item, index) => (
                          <li key={index} className="listedUser">
                            <Col className="listData">
                              <img src={Pic} alt="" />
                              <Col>
                                <h4>{item.name}</h4>
                                <h6>Referred <span>{item.referred}</span> on <span>{item.date}</span></h6>
                              </Col>
                            </Col>
                          </li>
                        ))}
                      </ul>
                    </Col>
                    <Col md={12}>
                      <ul>

                        {data.map((item, index) => (
                          <li key={index} className="listedUser">
                            <Col className="listData">
                              <img src={Pic} alt="" />
                              <Col>
                                <h4>{item.name}</h4>
                                <h6>Referred <span>{item.referred}</span> on <span>{item.date}</span></h6>
                              </Col>
                            </Col>
                          </li>
                        ))}
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row> */}
            <Row className=" px-6 recentlyView" style={{ margin: "14px 0" }}>
              <Col span={24} className="border-b border-[#EAECF0] pb-4">
                <h4 className="recentH4" style={{ marginBottom: "0" }}>
                  Recent Activity
                </h4>
              </Col>
            </Row>
            <Row style={{ margin: "14px 0" }} className=" px-6 recentlyViewBox">
              <Col className="recentBox">
                {activity?.map((item: any, i) => (
                  <Col key={i} span={24} className="mb-0 mt-3">
                    <ProfileSection
                      icon={item?.user?.profile_image}
                      heading={item?.AffiliateName}
                      name={item?.Name}
                      date={item?.add_time}
                    />
                  </Col>
                ))}
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <Spin fullscreen />
      )}
      <Booking isOpen={isBooking} onClose={() => setIsBooking(false)} />
    </>
  );
};

export default Main;
